:root {
    --col-black: #000;
    --col-white: #fff;

    --col-bd-gray: #E8E8E8;

    --tran-25: all .25s ease-in-out;
    --tran-45: all .45s cubic-bezier(0.645, 0.045, 0.355, 1);

    --hd-height: 8rem;
}

html {font-size: 62.5%; /* 16px */}
body {position: relative; background: #000; color: var(--col-white); font-family: 'Pretendard'; font-size: 1.6rem; font-weight: 400; letter-spacing: -0.02em; line-height: 1.25;}

@media (max-width: 992px) {
    html {font-size: 58.6%; /* 15px */}
}


/* scrollbar */
body::-webkit-scrollbar {width: 18px; height: 18px;}
body::-webkit-scrollbar-track {background-color: var(--col-black);}
body::-webkit-scrollbar-thumb {border-radius: 20px; border: 6px solid var(--col-black); background-color: var(--col-white);}
body::-webkit-scrollbar-button {width: 0; height: 0;}


/* Not Found = 404 */
.not_found {text-align: center; width: 450px; position: absolute; left:50%; top:50%; -webkit-transform: translate(-50%, -50%);  transform: translate(-50%, -50%);}
.not_found h3 {font-size: 81px; margin-bottom: 50px;}
.not_found h4 {font-size: 25px; margin-bottom: 10px; letter-spacing: -0.7px;}
.not_found p {opacity: .7; margin-bottom: 15px;}
.not_found a {color: #fff; background-color: var(--tm-col); padding: 10px 20px; border-radius: 5px;}



/* ============================================================
* Header
* ============================================================ */
header {position: fixed; top: 0; left: 0; right: 0; height: var(--hd-height); padding: 0 3rem 0 2rem; z-index: 10;}
header > div {display: flex; justify-content: space-between; align-items: center; width: 100%; max-width: calc(1420px + 5rem); height: 100%; margin: 0 auto;}
header h1 {width: 20rem; aspect-ratio: 194/68; background: url("/public/img/logo/verti_wt.png") left center/contain no-repeat;}
header h1 a {display: block; width: 100%; height: 100%;}

/* nav anchors */
.gnb {margin-top: 2rem;}
.gnb ul {display: flex; justify-content: center; align-items: center;}
.gnb ul li {margin: 0 1rem;}
.gnb ul li {position: relative; display: block; padding: 2rem; font-size: 1.8rem; letter-spacing: 0; cursor: pointer;}
.gnb ul li::before {content: ""; position: absolute; bottom: 0; left: 0; right: 0; width: 0; height: 2px; margin: auto; background: #fff; transition: var(--tran-25);}


header .right {margin-top: 2rem;}

/* button gitbook */
.btn-gitbook {display: inline-flex; justify-content: center; align-items: center; height: 46px; margin-right: 1.6rem; padding: 0px 14px; border-radius: 6px; border: 1px solid var(--col-white); transition: var(--tran-45);}
@media (hover: hover) and (pointer: fine) {
    .btn-gitbook:hover {box-shadow: 0 0 10px 0 rgba(255,255,255,50%) inset, 0 0 8px 2px rgba(255,255,255,50%);}
}

/* button Launch */
.btn-launch {position: relative; width: 175px; height: 46px; border-radius: 26px; background: rgba(255,255,255,20%) url("/public/img/launch.svg") 30px center/16px no-repeat; text-align: center; overflow: hidden;}
.btn-launch span {color: #fff; margin-left: 26px;}

.btn-launch::before,
.btn-launch::after {content: ""; position: absolute; top: 0; left: 0; height: 100%; transform: translateX(-100px) skewX(-15deg); transition: var(--tran-45);}
.btn-launch::before {opacity: 0.5; width: 60px; background: rgba(255, 255, 255, 0.5); filter: blur(30px);}
.btn-launch::after {width: 30px; left: 30px; background: rgba(255, 255, 255, 0.2); filter: blur(5px);}

@media (hover: hover) and (pointer: fine) {
    .gnb ul li:hover::before {width: calc(100% - 2rem);}
    .btn-launch:hover::before {opacity: 1;}
    .btn-launch:hover::before,
    .btn-launch:hover::after {transform: translateX(300px) skewX(-15deg);}
}
@media (max-width: 1400px) {
    .gnb ul li {margin: 0;}
    .gnb ul li {font-size: 1.6rem;}
}
@media (max-width: 1200px) {
    .gnb {display: none;}
    .btn-launch, .btn-gitbook {margin-top: 0;}
    .btn-launch {width: auto; aspect-ratio: 1; background-position: center;}
    .btn-launch span {font-size: 0;}
}
@media (max-width: 576px) {
    header {padding: 0 1rem;}
    header h1 {width: 15rem;}
}



/* ============================================================
* Launch app
* ============================================================ */
.launch-container {opacity: 0; visibility: hidden; position: fixed; top: 0; right: -20rem; height: 100dvh; width: 100%; max-width: 42rem; background-color: var(--col-black); transition: all 0.5s ease-in-out; z-index: 10;}
.launch-container.open {opacity: 1; visibility: visible; right: 0;}

.launch-head {position: relative; display: flex; justify-content: space-between; align-items: center; height: var(--hd-height); padding: 0 5rem;}
.launch-head::after {content: ""; position: absolute; bottom: 0; left: 3rem; right: 3rem; height: 1px; background: #fff;}
.launch-head .title {font-size: 2rem; font-weight: 700;}
.launch-head .btn-close {width: 24px; height: 24px; background: url(/public/img/btn_close.svg) center/contain no-repeat;}

.launch-content {height: calc(100dvh - var(--hd-height)); padding: 2rem 5rem; background: rgba(0,0,0,80%); overflow-y: auto;}
.launch-content::-webkit-scrollbar {width: 18px; height: 18px;}
.launch-content::-webkit-scrollbar-track {background-color: var(--col-black);}
.launch-content::-webkit-scrollbar-thumb {border-radius: 20px; border: 6px solid var(--col-black); background-color: var(--col-white);}
.launch-content::-webkit-scrollbar-button {width: 0; height: 0;}

/* navigation */
.snb {display: none;}
.snb li + li {margin-top: 0.5rem;}
.snb li a {display: block; padding: 1rem 0; font-size: 1.8rem;}
.snb li a span {position: relative;}
.snb li a span::before {content: ""; position: absolute; bottom: -0.6rem; left: 0; right: 0; width: 0; height: 1px; margin: auto; background: #fff; transition: var(--tran-25);}

/* accordion */
.accordion-container .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root,
.accordion-container .css-15v22id-MuiAccordionDetails-root {padding: 0;}
.accordion-container .css-eqpfi5-MuiAccordionSummary-content,
.accordion-container .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {margin: 0;}
.accordion-container .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root,
.accordion-container .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {min-height: auto;}
.accordion-container .MuiPaper-root,
.accordion-container .css-1086bdv-MuiPaper-root-MuiAccordion-root {background-color: transparent; color: #fff;}

.accordion-container .title {position: relative; width: 100%; padding: 1rem 3rem; font-size: 14px; font-weight: 600; letter-spacing: 0;}
.accordion-container .title::before {content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 2.4rem; height: 2.4rem; background: url("/public/img/logo/token.png") center/contain no-repeat;}
.accordion-container .title::after {content: ""; position: absolute; top: 50%; right: 0; transform: translateY(-50%); width: 2rem; height: 2rem; background: url("/public/img/arrow_accordion.svg") center/contain no-repeat;}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded .title::after {transform: rotate(180deg) translateY(50%);}

/* platform link */
.link-platform {position: relative;  display: block; padding: 2rem 0 2rem 8rem;}
.link-platform::before {content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 6.4rem; height: 6.4rem; background-position: left center; background-size: contain; background-repeat: no-repeat;}
.link-platform.bbtoken:before {background-image: url(/public/img/logo/token.png);}
.link-platform.youtube:before {background-image: url(/public/img/youtube.png);}
.link-platform.tnetscan:before {background-image: url(/public/img/logo/tnetscan.png);}
.link-platform.tnetwallet:before {background-image: url(/public/img/logo/tnetwallet.png);}
.link-platform p {text-align: left; color: #fff; font-size: 1.6rem; font-weight: 700;}
.link-platform p small {display: inline-block; text-align: left; color: #fff; font-size: 14px; font-weight: 400;}
a.link-platform {margin-top: 1.5rem;} 

@media (hover: hover) and (pointer: fine) {
    .snb li a:hover span::before {width: 100%;}
}
@media (max-width: 1200px) {
    .snb {display: block;}
    .accordion-container {margin-top: 4rem;}
}
@media (max-width: 576px) {
    .launch-head {padding: 0 2rem;}
    .launch-head::after {left: 2rem; right: 2rem;}
    .launch-content {padding: 2rem;}
}


/* ============================================================
* Footer
* ============================================================ */
footer {position: relative; padding-top: 4rem; background: #1C1C1C;}
footer .container {width: 100%; max-width: 1406px; margin: 0 auto;}

.ft-grid {display: grid; grid-template-columns: 1fr 1fr; gap: 2rem;}

.ft-logo {width: 18rem; aspect-ratio: 194/68; background: url("/public/img/logo/verti_wt.png") left center/contain no-repeat;}
.ft-info p {display: inline; margin-bottom: 1rem;}
.ft-info dl {display: inline-flex; align-items: center; gap: 1.2rem; margin-left: 5rem;}
.ft-info dl + dl {margin-top: 1rem;}
.ft-info dt {position: relative; padding-right: 1.2rem; color: #D4D5D9; font-size: 14px; font-weight: 600;}
.ft-info dt::after {content: ""; position: absolute; top: .5rem; right: 0; width: 1px; height: 6px; background: #D4D5D9;}
.ft-info dd {letter-spacing: 0;}

.ft-policy {display: flex; justify-content: flex-end; align-items: center; gap: 2rem; text-align: right; color: #fff;}

footer .sns {display: flex; justify-content: flex-end; gap: 1rem;}
footer .sns button,
footer .sns a {width: 35px; height: 35px; background-position: center; background-size: 21px; background-repeat: no-repeat; border: 1px solid #fff; border-radius: 50%;}
footer .sns .X {background-image: url("/public/img/sns_x.png");}
footer .sns .Medium {background-image: url("/public/img/sns_medium.png");}
footer .sns .Telegram {background-image: url("/public/img/sns_telegram.png");}

footer .copyright {margin-top: 4rem; padding: 3rem 0; border-top: 1px solid #fff; color: #fff;}

@media (max-width: 1500px) {
    footer .container {padding: 0 2rem;}
}
@media (max-width: 768px) {
    .ft-grid {grid-template-columns: 1fr;}
    .ft-policy {justify-content: left;}
    .ft-info dl {margin-left: 2rem;}
}



/* ============================================================
* Full page
* ============================================================ */
.section {width: 100%;}

/* container */
.fp-section .fp-overflow {height: 100%;}
.fp-warning, .fp-watermark {display: none;}

/* header scroll */
header.reverse {background: var(--col-black);}



/* ============================================================
* Common
* ============================================================ */
.sec-title {text-align: center;}
.sec-title h3 {font-size: 5.5rem; font-weight: 700;}
.sec-title p {margin-top: 4.6rem; font-size: 2rem; font-weight: 400;}

@media (max-width: 768px) {
    .sec-title {margin-bottom: 5rem;}
    .sec-title h3 {font-size: 4rem;}
}


/* ============================================================
* MAIN SECTION 1
* ============================================================ */
.fullpage-wrapper .section.sec01 {padding-top: 0;}

/* tsparticles/react */
.mainSec01 {position: relative;}
.tsparticles {position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none;}

.main-slide {height: 100dvh; z-index: 0;}
.main-slide .swiper-slide {background-position: center; background-size: cover; background-repeat: no-repeat;}

/* 네비게이션(화살표) */
.main-slide .swiper-button-next, .main-slide .swiper-button-prev {color: #fff;}
.main-slide .swiper-button-next:after, .main-slide .swiper-button-prev:after {font-size: 2.2rem;}
.main-slide .swiper-button-next {right: 4rem;}
.main-slide .swiper-button-prev {left: 4rem;}

/* 슬라이드 문구 */
.slide-txt {position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; max-width: calc(1376px + 12rem); padding: 0 6rem; color: #fff;}
.slide-txt p {font-size: 2rem; line-height: 1.5;}
.slide-txt h2 {margin-bottom: 2rem; font-size: 6.5rem; font-weight: 700; letter-spacing: 0;}
.slide-txt h3 {font-size: 12rem; font-weight: 700; text-transform: uppercase;}
.slide-txt h4 {margin-bottom: 2rem; font-size: 6.5rem; font-weight: 700;}

.img-token {width: 40rem; aspect-ratio: 1; background: url("/public/img/logo/token.png") center/contain no-repeat;}
.img-earth {position: absolute; bottom: 0; width: 100%; height: 43%; background: url("/public/img/visual/visual_earth.png") center/auto 100% no-repeat;}

/* 슬라이드 문구 위치 */
.slide01 .slide-txt {display: flex; justify-content: space-between; align-items: center;}
.slide02 .slide-txt {top: calc(50% - 10rem); text-align: center;}

.swiper-slide-active .slide-txt p,
.swiper-slide-active .slide-txt h2,
.swiper-slide-active .slide-txt h4,
.swiper-slide-active .slide-txt div,
.swiper-slide-active .slide-txt a,
.swiper-slide-active .slide-txt button,
.swiper-slide-active .img-earth {opacity: 0; animation: visualAnimation 0.5s ease-in-out 0.5s forwards;}
.swiper-slide-active .slide-txt .img-token {opacity: 0; animation: rotate-vert-center 1.25s ease-in forwards;}

/* 버튼 */
.hashtag {position: relative; display: inline-flex; justify-content: center; align-items: center; width: 100%; max-width: 22rem; height: 5rem; margin-top: 5rem; background: linear-gradient(to right, #FF3BFF, #ECBFBF, #5C24FF, #D94FD5); border-radius: 10rem;}
.hashtag span {display: inline-flex; justify-content: center; align-items: center; width: calc(100% - 5px); height: calc(100% - 5px); background: var(--col-black); border-radius: 10rem; color: #fff; font-size: 1.6rem; letter-spacing: 0; line-height: 1;}


.slide02 .slide-txt .hashtag {margin: 2.2rem 0.5rem 0;}


@keyframes visualAnimation {
  from {opacity: 0; transform: translateY(3rem);}
  to {opacity: 1; transform: translateY(0);}
}
@keyframes rotate-vert-center {
    0% {opacity: 0; transform: rotateY(0) translateY(6rem);}
    50% {transform: rotateY(180deg);}
    100% {opacity: 1; transform: rotateY(360deg) translateY(0);}
}


@media (max-width: 1600px) {
    .main-slide .swiper-button-next {right: 2rem;}
    .main-slide .swiper-button-prev {left: 2rem;}
}
@media (max-width: 576px) {
    .main-slide .swiper-button-next,
    .main-slide .swiper-button-prev {display: none;}
    .slide-txt h2 {font-size: 5.5rem;}
    .img-token {display: none;}
}


/* ============================================================
* MAIN SECTION 2
* ============================================================ */
.mainSec02 {position: relative; height: 100%; background: url("../../public/img/sec02_bg.jpg") center/cover no-repeat; z-index: 1; overflow: hidden;}

.mainSec02 > .container {display: flex; justify-content: center; align-items: center; width: 100%; max-width: calc(1490px + 8rem); height: 100%; margin: 0 auto; padding: 0 4rem;}
.mainSec02 > .container .left {flex: 1 0 0;}
.mainSec02 > .container .right {flex: 1 0 auto; max-width: 99rem; z-index: 1;}

.mainSec02 h3 {margin-bottom: 7.6rem; font-size: 5.5rem; font-weight: 700;}
.mainSec02 p {font-size: 2rem; line-height: 1.5;}

.mainSec02 .img {width: 100%; aspect-ratio: 998/748; background: url("../../public/img/sec02_platform.png") center/cover no-repeat;}

/* animation */
.sec02.active .left {opacity: 0; animation: txtLeftAnimation 0.5s ease-in-out 0.5s forwards;}
.sec02.active .right {opacity: 0; animation: txtRightAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtLeftAnimation {
    from {opacity: 0; transform: translateX(-3rem);}
    to {opacity: 1; transform: translateX(0);}
}
@keyframes txtRightAnimation {
    from {opacity: 0; transform: translateX(3rem);}
    to {opacity: 1; transform: translateX(0);}
}

@media (max-width: 1200px) {
    .mainSec02 > .container {flex-direction: column-reverse; align-items: center;}
    .mainSec02 > .container .left {flex: 0;}
    .mainSec02 > .container .right {flex: 0 ; width: 60%;}
    .mainSec02 h3 {margin-bottom: 1rem;}
}
@media (max-width: 992px) {
    .mainSec02 > .container {padding: 5rem 0 10rem;}
    .mainSec02 > .container .left {padding: 0 4rem;}
}
@media (max-width: 576px) {
    .mainSec02 > .container .left {padding: 0 2rem;}
    .mainSec02 > .container .right {width: 80%;}
    .mainSec02 h3 {font-size: 4.5rem;}
}


/* ============================================================
* MAIN SECTION 3
* ============================================================ */
.mainSec03 {display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding: calc(var(--hd-height) + 4rem) 2rem 4rem;}
.solution-list {display: flex; flex-wrap: wrap; justify-content: center; gap: 2.2rem 2.7rem; max-width: 1640px; margin: 4% auto 0;}
.solution-list > li {flex: 0 0 calc(25% - 2.1rem); min-height: 23rem; background: url("/public/img/icon_check.svg") center 3.7rem/4.3rem no-repeat; border: 1px solid rgba(232,232,232,80%); border-radius: 2.4rem; padding: 10rem 2rem 2rem; text-align: center; font-size: 1.8rem; font-weight: 500; letter-spacing: 0; line-height: 1.45;}

/* animation */
.sec03.active .solution-list > li {opacity: 0; animation: txtUpAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtUpAnimation {
    from {opacity: 0; transform: translateY(3rem);}
    to {opacity: 1; transform: translateY(0);}
}


@media (max-width: 1200px) {
    .solution-list > li {min-height: auto; padding: 8rem 2rem 2rem; background-size: 3rem; font-size: 1.5rem;}
}
@media (max-width: 992px) {
    .mainSec03 {position: relative; padding: 10rem 2rem; background: var(--col-black);}
}
@media (max-width: 768px) {
    .solution-list {gap: 1rem;}
    .solution-list > li {flex: 0 0 calc(33.33% - 1rem);}
}
@media (max-width: 576px) {
    .solution-list > li {flex: 0 0 100%;}
}


/* ============================================================
* MAIN SECTION 4
* ============================================================ */
.mainSec04 {position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding: calc(var(--hd-height) + 4rem) 2rem 4rem; height: 100%; background: url("../../public/img/sec04_bg.jpg") center/cover no-repeat; z-index: 1; overflow: hidden;}
.mainSec04 .phone {height: 70%; aspect-ratio: 907/806; margin: 0 auto; background: url("../../public/img/sec04_phone02.png") center/contain no-repeat;}

/* animation */
.sec04.active .sec-title h3,
.sec04.active .sec-title p,
.sec04.active .mainSec04 .phone {opacity: 0; animation: txtUpAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtUpAnimation {
    from {opacity: 0; transform: translateY(3rem);}
    to {opacity: 1; transform: translateY(0);}
}

@media (max-width: 992px) {
    .mainSec04 {padding: 10rem 2rem;}
    .mainSec04 .phone {width: 70%; height: auto;}
}
@media (max-width: 576px) {
    .mainSec04 .phone {width: 100%;}
}


/* ============================================================
* MAIN SECTION 5 = Ecosystem
* ============================================================ */
.mainSec05 {position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding: calc(var(--hd-height) + 4rem) 2rem 4rem; height: 100%;}
.mainSec05::before {content: ""; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: url("/public/img/sec05_bg.png") center/contain no-repeat; z-index: -1;}

.eco-list {display: flex; flex-wrap: wrap; justify-content: center; gap: 1.8rem; max-width: 1420px; margin: 4% auto 0;}
.eco-list > li {flex: 0 0 calc(33.33% - 1.2rem); background: rgba(255,255,255,20%); border-radius: 1.2rem; padding: 2.4rem 2.4rem 2rem;}

.eco-list .head {margin-bottom: 2.4rem; padding-bottom: 1.2rem; border-bottom: 1px solid #fff;}
.eco-list .head > p {margin-bottom: 1.2rem; font-size: 14px; letter-spacing: 0.4rem;}
.eco-list .head .title {padding-top: calc(4rem + 1.2rem); background-position: left top; background-size: 4rem; background-repeat: no-repeat; font-size: 2.4rem; font-weight: 600;}

.eco-list > li:nth-child(1) .title {background-image: url("/public/img/eco01.png");}
.eco-list > li:nth-child(2) .title {background-image: url("/public/img/eco02.png");}
.eco-list > li:nth-child(3) .title {background-image: url("/public/img/eco03.png");}
.eco-list > li:nth-child(4) .title {background-image: url("/public/img/eco04.png");}
.eco-list > li:nth-child(5) .title {background-image: url("/public/img/eco05.png");}
.eco-list > li:nth-child(6) .title {background-image: url("/public/img/eco06.png");}

/* animation */
.sec05.active .eco-list > li {opacity: 0; animation: txtUpAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtUpAnimation {
    from {opacity: 0; transform: translateY(3rem);}
    to {opacity: 1; transform: translateY(0);}
}

@media (min-width: 1921px) {
    .eco-list > li {min-height: 34rem;}
}
@media (max-width: 1200px) {
    .eco-list .head .title {min-height: 11rem;}
}
@media (max-width: 992px) {
    .mainSec05 {padding: 10rem 2rem; background: var(--col-black);}
    .eco-list > li {flex: 0 0 calc(50% - 1.2rem);}
    .eco-list .head .title {min-height: auto; font-size: 2.2rem;}
}
@media (max-width: 768px) {
    .eco-list > li {flex: 0 0 100%; min-height: 30rem;}
    .eco-list .head .title {font-size: 1.8rem;}
}



/* ============================================================
* MAIN SECTION 6 = Technical
* ============================================================ */
.mainSec06 {position: relative; display: flex; flex-direction: column; justify-content: flex-end; align-items: center; height: 100%; padding-top: calc(var(--hd-height) + 4rem); background: url("../../public/img/sec06_bg.jpg") center/cover no-repeat;}

.tech-list {display: flex; flex-wrap: wrap; justify-content: center; height: 75%; margin: 4% auto 0; border-top: 1px solid #fff;}
.tech-list > li {flex: 1 0 0; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding: 0 4rem; background: rgba(0,0,0,50%); text-align: center;}
.tech-list > li:not(:last-child) {border-right: 1px solid #fff;}
 
.tech-list > li .title {margin-bottom: 3rem; padding-top: calc(7.2rem + 4.6rem); background-position: center top; background-size: 7.2rem; background-repeat: no-repeat; font-size: 4rem; font-weight: 700;}
.tech-list > li:nth-child(1) .title {background-image: url("/public/img/tech01.png");}
.tech-list > li:nth-child(2) .title {background-image: url("/public/img/tech02.png");}
.tech-list > li:nth-child(3) .title {background-image: url("/public/img/tech03.png");}
.tech-list > li p {font-size: 2rem; line-height: 1.5;}

/* animation */
.sec06.active .tech-list > li > div {opacity: 0; animation: txtUpAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtUpAnimation {
    from {opacity: 0; transform: translateY(3rem);}
    to {opacity: 1; transform: translateY(0);}
}

@media (max-width: 992px) {
    .mainSec06 {padding: 10rem 2rem;}
    .tech-list {height: auto; border: 1px solid #fff;}
    .tech-list > li {justify-content: flex-start; height: auto; padding: 4rem 2rem;}
    .tech-list > li .title {min-height: 14rem; padding-top: calc(7.2rem + 1.7rem); font-size: 2rem;}
}
@media (max-width: 768px) {
    .tech-list > li {flex: 1 0 100%;}
    .tech-list > li:not(:last-child) {border-bottom: 1px solid #fff;}
    .tech-list > li .title {min-height: auto;}
}



/* ============================================================
* MAIN SECTION 7 = Use Cases
* ============================================================ */
.mainSec07 {position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding: calc(var(--hd-height) + 4rem) 2rem 4rem; background: url("../../public/img/sec07_bg.jpg") center/cover no-repeat;}

.case-list {display: flex; flex-direction: column; width: 100%; max-width: 1420px; height: 85%; margin: 4% auto 0; border: 1px solid #fff;}
.case-list > li {flex: 1 0 0; display: flex; align-items: center; gap: 4rem; padding: 0 10rem;}
.case-list > li:not(:last-child) {border-bottom: 1px solid #fff;}

.case-list > li > div {font-size: 3.2rem; font-weight: 700;}
.case-list > li > ul {display: flex; align-items: center; gap: 2.4rem;}
.case-list > li > ul > li {position: relative; padding-left: 1.5rem; font-size: 1.8rem;}
.case-list > li > ul > li::before {content: ""; position: absolute; top: 0.9rem; left: 0; width: 4px; height: 4px; border-radius: 50%; background: #fff;}

/* animation */
.sec07.active .case-list > li > div {opacity: 0; animation: txtLeftAnimation 0.5s ease-in-out 0.5s forwards;}
.sec07.active .case-list > li > ul {opacity: 0; animation: txtRightAnimation 0.5s ease-in-out 0.5s forwards;}
@keyframes txtLeftAnimation {
    from {opacity: 0; transform: translateX(-3rem);}
    to {opacity: 1; transform: translateX(0);}
}
@keyframes txtRightAnimation {
    from {opacity: 0; transform: translateX(3rem);}
    to {opacity: 1; transform: translateX(0);}
}


@media (max-width: 1250px) {
    .case-list > li {flex-direction: column; justify-content: center; align-items: flex-start; gap: 1rem; padding: 2rem 5rem;}
}
@media (max-width: 992px) {
    .mainSec07 {padding: 10rem 2rem;}
}
@media (max-width: 768px) {
    .case-list > li > div {font-size: 2rem;}
    .case-list > li > ul {flex-direction: column; justify-content: center; align-items: flex-start; gap: 1rem;}
    .case-list > li > ul > li {font-size: 14px;}
} 