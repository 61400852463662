/* Tag Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
textarea, input, button, select,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {padding:0; margin:0; border:0; border-radius:0; outline:0; font-style:inherit; font-weight:inherit; font-size:inherit; font-family:inherit; vertical-align:baseline; box-sizing: border-box;}
caption {font-size:0; display:none;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block;}
html, body {width:100%;}/*height:100%;*/
html { -webkit-text-size-adjust:100%; -moz-text-size-adjust:100%; -ms-text-size-adjust:100%;}
body {margin:0 auto; overflow-x: hidden; overflow-y: auto; word-break:keep-all; font-style:normal; font-weight:normal; line-height:1.25; background-color:#fff; box-sizing: border-box;}
dl, ul, ol, li {list-style:none;}
label, input, button, select {vertical-align:middle;}
iframe, img { vertical-align:top;}
img {max-width:100%;}
button, input[type="button"], input[type="submit"] {cursor:pointer; vertical-align:middle;}
button {background-color: transparent; color: inherit; box-sizing: border-box;}
input[type="text"]:focus, input[type="password"]:focus, textarea:focus,select:focus {-webkit-box-shadow:none; -moz-box-shadow:none; box-shadow:none;}
textarea {overflow:auto; resize:vertical;}
table {width:100%; max-width:100%; border-collapse:collapse; border-spacing:0;}
h1, h2, h3, h4, h5, h6, strong, b { font-weight:bold }
a {text-decoration:none; color: inherit;}
em,span,i,a,strong,b {display:inline-block; font-style:normal;}
small {font-size: 90%;}

.readonly {background-color: #f7f7f7 !important}
.op {opacity:1 !important}

/* Sound only */
legend, caption, .sound_only {position:absolute; width:0; height:1px; padding:0; margin:-1px; border:0; clip:rect(1px 1px 1px 1px); clip:rect(1px, 1px, 1px, 1px); overflow:hidden;}

/* List Style Definition */
ul.li01 li {width:100%;}
ul.li02 li {width:50%;}
ul.li03 li {width:33.3%;}
ul.li04 li {width:25%;}
ul.li05 li {width:20%;}
ul.li06 li {width:16.6%;}
ul.li07 li {width:14.2587%;}
ul.li08 li {width:12.5%;}
ul.li09 li {width:11.1111%;}
ul.li10 li {width:10%;}

/* Font-weight */
.fwb {font-weight: bold !important;}

/* Width */
.wd_1 {width:1% !important;}
.wd_2 {width:2% !important;}
.wd_3 {width:3% !important;}
.wd_4 {width:4% !important;}
.wd_5 {width:5% !important;}
.wd_6 {width:6% !important;}
.wd_7 {width:7% !important;}
.wd_8 {width:8% !important;}
.wd_9 {width:9% !important;}
.wd_10 {width:10% !important;}
.wd_11 {width:11% !important;}
.wd_12 {width:12% !important;}
.wd_13 {width:13% !important;}
.wd_14 {width:14% !important;}
.wd_15 {width:15% !important;}
.wd_16 {width:16% !important;}
.wd_17 {width:17% !important;}
.wd_18 {width:18% !important;}
.wd_19 {width:19% !important;}
.wd_20 {width:20% !important;}
.wd_21 {width:21% !important;}
.wd_22 {width:22% !important;}
.wd_23 {width:23% !important;}
.wd_24 {width:24% !important;}
.wd_25 {width:25% !important;}
.wd_26 {width:26% !important;}
.wd_27 {width:27% !important;}
.wd_28 {width:28% !important;}
.wd_29 {width:29% !important;}
.wd_30 {width:30% !important;}
.wd_31 {width:31% !important;}
.wd_32 {width:32% !important;}
.wd_33 {width:33% !important;}
.wd_34 {width:34% !important;}
.wd_35 {width:35% !important;}
.wd_36 {width:36% !important;}
.wd_37 {width:37% !important;}
.wd_38 {width:38% !important;}
.wd_39 {width:39% !important;}
.wd_40 {width:40% !important;}
.wd_41 {width:41% !important;}
.wd_42 {width:42% !important;}
.wd_43 {width:43% !important;}
.wd_44 {width:44% !important;}
.wd_45 {width:45% !important;}
.wd_46 {width:46% !important;}
.wd_47 {width:47% !important;}
.wd_48 {width:48% !important;}
.wd_49 {width:49% !important;}
.wd_50 {width:50% !important;}
.wd_51 {width:51% !important;}
.wd_52 {width:52% !important;}
.wd_53 {width:53% !important;}
.wd_54 {width:54% !important;}
.wd_55 {width:55% !important;}
.wd_56 {width:56% !important;}
.wd_57 {width:57% !important;}
.wd_58 {width:58% !important;}
.wd_59 {width:59% !important;}
.wd_60 {width:60% !important;}
.wd_61 {width:61% !important;}
.wd_62 {width:62% !important;}
.wd_63 {width:63% !important;}
.wd_64 {width:64% !important;}
.wd_65 {width:65% !important;}
.wd_66 {width:66% !important;}
.wd_67 {width:67% !important;}
.wd_68 {width:68% !important;}
.wd_69 {width:69% !important;}
.wd_70 {width:70% !important;}
.wd_71 {width:71% !important;}
.wd_72 {width:72% !important;}
.wd_73 {width:73% !important;}
.wd_74 {width:74% !important;}
.wd_75 {width:75% !important;}
.wd_76 {width:76% !important;}
.wd_77 {width:77% !important;}
.wd_78 {width:78% !important;}
.wd_79 {width:79% !important;}
.wd_80 {width:80% !important;}
.wd_81 {width:81% !important;}
.wd_82 {width:82% !important;}
.wd_83 {width:83% !important;}
.wd_84 {width:84% !important;}
.wd_85 {width:85% !important;}
.wd_86 {width:86% !important;}
.wd_87 {width:87% !important;}
.wd_88 {width:88% !important;}
.wd_89 {width:89% !important;}
.wd_90 {width:90% !important;}
.wd_91 {width:91% !important;}
.wd_92 {width:92% !important;}
.wd_93 {width:93% !important;}
.wd_94 {width:94% !important;}
.wd_95 {width:95% !important;}
.wd_96 {width:96% !important;}
.wd_97 {width:97% !important;}
.wd_98 {width:98% !important;}
.wd_99 {width:99% !important;}
.wd_100 {width:100% !important;}

/* Style Definition */
.fl {float:left !important;}
.fr {float:right !important;}
.fn {float:none !important;}
.half {width:50%;}
.tl {text-align:left !important;}
.tr {text-align:right !important;}
.tc {text-align:center !important;}
.clear {clear:both;}
.required:after {content:'*'; color:red; font-size:18px; position: absolute; right:5px; top:5px;}
.bgn {background:none !important;}
.dis_none {display:none !important;}

/* Position */
.posr {position:relative !important;}
.posa {position:absolute !important;}
.posf {position:fixed !important;}
.posi {position:inherit !important;}

/* Display */
.disn {display:none !important;}
.disb {display:block !important;}
.disib {display:inline-block !important;}
.distc {display:table-cell !important;}

/* Overflow */
.ovh {overflow:hidden !important;}
.ovy_scroll {overflow-y:scroll !important;}
.ovx_scroll {overflow-x:scroll !important;}

/* Margin */
.mg0 {margin:0 !important;}
.mg1 {margin:1px !important;}
.mg2 {margin:2px !important;}
.mg3 {margin:3px !important;}
.mg4 {margin:4px !important;}
.mg5 {margin:5px !important;}
.mg6 {margin:6px !important;}
.mg7 {margin:7px !important;}
.mg8 {margin:8px !important;}
.mg9 {margin:9px !important;}
.mg10 {margin:10px !important;}
.mg11 {margin:11px !important;}
.mg12 {margin:12px !important;}
.mg13 {margin:13px !important;}
.mg14 {margin:14px !important;}
.mg15 {margin:15px !important;}
.mg16 {margin:16px !important;}
.mg17 {margin:17px !important;}
.mg18 {margin:18px !important;}
.mg19 {margin:19px !important;}
.mg20 {margin:20px !important;}
.mg21 {margin:21px !important;}
.mg22 {margin:22px !important;}
.mg23 {margin:23px !important;}
.mg24 {margin:24px !important;}
.mg25 {margin:25px !important;}
.mg26 {margin:26px !important;}
.mg27 {margin:27px !important;}
.mg28 {margin:28px !important;}
.mg29 {margin:29px !important;}
.mg30 {margin:30px !important;}
.mg31 {margin:31px !important;}
.mg32 {margin:32px !important;}
.mg33 {margin:33px !important;}
.mg34 {margin:34px !important;}
.mg35 {margin:35px !important;}
.mg36 {margin:36px !important;}
.mg37 {margin:37px !important;}
.mg38 {margin:38px !important;}
.mg39 {margin:39px !important;}
.mg40 {margin:40px !important;}
.mg41 {margin:41px !important;}
.mg42 {margin:42px !important;}
.mg43 {margin:43px !important;}
.mg44 {margin:44px !important;}
.mg45 {margin:45px !important;}
.mg46 {margin:46px !important;}
.mg47 {margin:47px !important;}
.mg48 {margin:48px !important;}
.mg49 {margin:49px !important;}
.mg50 {margin:50px !important;}
.mg51 {margin:51px !important;}
.mg52 {margin:52px !important;}
.mg53 {margin:53px !important;}
.mg54 {margin:54px !important;}
.mg55 {margin:55px !important;}
.mg56 {margin:56px !important;}
.mg57 {margin:57px !important;}
.mg58 {margin:58px !important;}
.mg59 {margin:59px !important;}
.mg60 {margin:60px !important;}
.mg61 {margin:61px !important;}
.mg62 {margin:62px !important;}
.mg63 {margin:63px !important;}
.mg64 {margin:64px !important;}
.mg65 {margin:65px !important;}
.mg66 {margin:66px !important;}
.mg67 {margin:67px !important;}
.mg68 {margin:68px !important;}
.mg69 {margin:69px !important;}
.mg70 {margin:70px !important;}
.mg71 {margin:71px !important;}
.mg72 {margin:72px !important;}
.mg73 {margin:73px !important;}
.mg74 {margin:74px !important;}
.mg75 {margin:75px !important;}
.mg76 {margin:76px !important;}
.mg77 {margin:77px !important;}
.mg78 {margin:78px !important;}
.mg79 {margin:79px !important;}
.mg80 {margin:80px !important;}
.mg81 {margin:81px !important;}
.mg82 {margin:82px !important;}
.mg83 {margin:83px !important;}
.mg84 {margin:84px !important;}
.mg85 {margin:85px !important;}
.mg86 {margin:86px !important;}
.mg87 {margin:87px !important;}
.mg87 {margin:88px !important;}
.mg89 {margin:89px !important;}
.mg90 {margin:90px !important;}
.mg91 {margin:91px !important;}
.mg92 {margin:92px !important;}
.mg93 {margin:93px !important;}
.mg94 {margin:94px !important;}
.mg95 {margin:95px !important;}
.mg96 {margin:96px !important;}
.mg97 {margin:97px !important;}
.mg98 {margin:98px !important;}
.mg99 {margin:99px !important;}
.mg100 {margin:100px !important;}

.mt0 {margin-top:0px !important;}
.mt1 {margin-top:1px !important;}
.mt2 {margin-top:2px !important;}
.mt3 {margin-top:3px !important;}
.mt4 {margin-top:4px !important;}
.mt5 {margin-top:5px !important;}
.mt6 {margin-top:6px !important;}
.mt7 {margin-top:7px !important;}
.mt8 {margin-top:8px !important;}
.mt9 {margin-top:9px !important;}
.mt10 {margin-top:10px !important;}
.mt11 {margin-top:11px !important;}
.mt12 {margin-top:12px !important;}
.mt13 {margin-top:13px !important;}
.mt14 {margin-top:14px !important;}
.mt15 {margin-top:15px !important;}
.mt16 {margin-top:16px !important;}
.mt17 {margin-top:17px !important;}
.mt18 {margin-top:18px !important;}
.mt19 {margin-top:19px !important;}
.mt20 {margin-top:20px !important;}
.mt21 {margin-top:21px !important;}
.mt22 {margin-top:22px !important;}
.mt23 {margin-top:23px !important;}
.mt24 {margin-top:24px !important;}
.mt25 {margin-top:25px !important;}
.mt26 {margin-top:26px !important;}
.mt27 {margin-top:27px !important;}
.mt28 {margin-top:28px !important;}
.mt29 {margin-top:29px !important;}
.mt30 {margin-top:30px !important;}
.mt31 {margin-top:31px !important;}
.mt32 {margin-top:32px !important;}
.mt33 {margin-top:33px !important;}
.mt34 {margin-top:34px !important;}
.mt35 {margin-top:35px !important;}
.mt36 {margin-top:36px !important;}
.mt37 {margin-top:37px !important;}
.mt38 {margin-top:38px !important;}
.mt39 {margin-top:39px !important;}
.mt40 {margin-top:40px !important;}
.mt41 {margin-top:41px !important;}
.mt42 {margin-top:42px !important;}
.mt43 {margin-top:43px !important;}
.mt44 {margin-top:44px !important;}
.mt45 {margin-top:45px !important;}
.mt46 {margin-top:46px !important;}
.mt47 {margin-top:47px !important;}
.mt48 {margin-top:48px !important;}
.mt49 {margin-top:49px !important;}
.mt50 {margin-top:50px !important;}
.mt55 {margin-top:55px !important;}
.mt60 {margin-top:60px !important;}
.mt65 {margin-top:65px !important;}
.mt70 {margin-top:70px !important;}
.mt75 {margin-top:75px !important;}
.mt80 {margin-top:80px !important;}
.mt85 {margin-top:85px !important;}
.mt90 {margin-top:90px !important;}
.mt95 {margin-top:95px !important;}
.mt100 {margin-top:100px !important;}

.mb0 {margin-bottom:0px !important;}
.mb1 {margin-bottom:1px !important;}
.mb2 {margin-bottom:2px !important;}
.mb3 {margin-bottom:3px !important;}
.mb4 {margin-bottom:4px !important;}
.mb5 {margin-bottom:5px !important;}
.mb6 {margin-bottom:6px !important;}
.mb7 {margin-bottom:7px !important;}
.mb8 {margin-bottom:8px !important;}
.mb9 {margin-bottom:9px !important;}
.mb10 {margin-bottom:10px !important;}
.mb11 {margin-bottom:11px !important;}
.mb12 {margin-bottom:12px !important;}
.mb13 {margin-bottom:13px !important;}
.mb14 {margin-bottom:14px !important;}
.mb15 {margin-bottom:15px !important;}
.mb16 {margin-bottom:16px !important;}
.mb17 {margin-bottom:17px !important;}
.mb18 {margin-bottom:18px !important;}
.mb19 {margin-bottom:19px !important;}
.mb20 {margin-bottom:20px !important;}
.mb21 {margin-bottom:21px !important;}
.mb22 {margin-bottom:22px !important;}
.mb23 {margin-bottom:23px !important;}
.mb24 {margin-bottom:24px !important;}
.mb25 {margin-bottom:25px !important;}
.mb26 {margin-bottom:26px !important;}
.mb27 {margin-bottom:27px !important;}
.mb28 {margin-bottom:28px !important;}
.mb29 {margin-bottom:29px !important;}
.mb30 {margin-bottom:30px !important;}
.mb31 {margin-bottom:31px !important;}
.mb32 {margin-bottom:32px !important;}
.mb33 {margin-bottom:33px !important;}
.mb34 {margin-bottom:34px !important;}
.mb35 {margin-bottom:35px !important;}
.mb36 {margin-bottom:36px !important;}
.mb37 {margin-bottom:37px !important;}
.mb38 {margin-bottom:38px !important;}
.mb39 {margin-bottom:39px !important;}
.mb40 {margin-bottom:40px !important;}
.mb41 {margin-bottom:41px !important;}
.mb42 {margin-bottom:42px !important;}
.mb43 {margin-bottom:43px !important;}
.mb44 {margin-bottom:44px !important;}
.mb45 {margin-bottom:45px !important;}
.mb46 {margin-bottom:46px !important;}
.mb47 {margin-bottom:47px !important;}
.mb48 {margin-bottom:48px !important;}
.mb49 {margin-bottom:49px !important;}
.mb50 {margin-bottom:50px !important;}
.mb55 {margin-bottom:55px !important;}
.mb60 {margin-bottom:60px !important;}
.mb65 {margin-bottom:65px !important;}
.mb70 {margin-bottom:70px !important;}
.mb75 {margin-bottom:75px !important;}
.mb80 {margin-bottom:80px !important;}
.mb85 {margin-bottom:85px !important;}
.mb90 {margin-bottom:90px !important;}
.mb95 {margin-bottom:95px !important;}
.mb100 {margin-bottom:100px !important;}

.mr0 {margin-right:0px !important;}
.mr1 {margin-right:1px !important;}
.mr2 {margin-right:2px !important;}
.mr3 {margin-right:3px !important;}
.mr4 {margin-right:4px !important;}
.mr5 {margin-right:5px !important;}
.mr6 {margin-right:6px !important;}
.mr7 {margin-right:7px !important;}
.mr8 {margin-right:8px !important;}
.mr9 {margin-right:9px !important;}
.mr10 {margin-right:10px !important;}
.mr11 {margin-right:11px !important;}
.mr12 {margin-right:12px !important;}
.mr13 {margin-right:13px !important;}
.mr14 {margin-right:14px !important;}
.mr15 {margin-right:15px !important;}
.mr16 {margin-right:16px !important;}
.mr17 {margin-right:17px !important;}
.mr18 {margin-right:18px !important;}
.mr19 {margin-right:19px !important;}
.mr20 {margin-right:20px !important;}
.mr21 {margin-right:21px !important;}
.mr22 {margin-right:22px !important;}
.mr23 {margin-right:23px !important;}
.mr24 {margin-right:24px !important;}
.mr25 {margin-right:25px !important;}
.mr26 {margin-right:26px !important;}
.mr27 {margin-right:27px !important;}
.mr28 {margin-right:28px !important;}
.mr29 {margin-right:29px !important;}
.mr30 {margin-right:30px !important;}
.mr31 {margin-right:31px !important;}
.mr32 {margin-right:32px !important;}
.mr33 {margin-right:33px !important;}
.mr34 {margin-right:34px !important;}
.mr35 {margin-right:35px !important;}
.mr36 {margin-right:36px !important;}
.mr37 {margin-right:37px !important;}
.mr38 {margin-right:38px !important;}
.mr39 {margin-right:39px !important;}
.mr40 {margin-right:40px !important;}
.mr41 {margin-right:41px !important;}
.mr42 {margin-right:42px !important;}
.mr43 {margin-right:43px !important;}
.mr44 {margin-right:44px !important;}
.mr45 {margin-right:45px !important;}
.mr46 {margin-right:46px !important;}
.mr47 {margin-right:47px !important;}
.mr48 {margin-right:48px !important;}
.mr49 {margin-right:49px !important;}
.mr50 {margin-right:50px !important;}
.mr55 {margin-right:55px !important;}
.mr60 {margin-right:60px !important;}
.mr65 {margin-right:65px !important;}
.mr70 {margin-right:70px !important;}
.mr75 {margin-right:75px !important;}
.mr80 {margin-right:80px !important;}
.mr85 {margin-right:85px !important;}
.mr90 {margin-right:90px !important;}
.mr95 {margin-right:95px !important;}
.mr100 {margin-right:100px !important;}

.ml0 {margin-left:0px !important;}
.ml1 {margin-left:1px !important;}
.ml2 {margin-left:2px !important;}
.ml3 {margin-left:3px !important;}
.ml4 {margin-left:4px !important;}
.ml5 {margin-left:5px !important;}
.ml6 {margin-left:6px !important;}
.ml7 {margin-left:7px !important;}
.ml8 {margin-left:8px !important;}
.ml9 {margin-left:9px !important;}
.ml10 {margin-left:10px !important;}
.ml11 {margin-left:11px !important;}
.ml12 {margin-left:12px !important;}
.ml13 {margin-left:13px !important;}
.ml14 {margin-left:14px !important;}
.ml15 {margin-left:15px !important;}
.ml16 {margin-left:16px !important;}
.ml17 {margin-left:17px !important;}
.ml18 {margin-left:18px !important;}
.ml19 {margin-left:19px !important;}
.ml20 {margin-left:20px !important;}
.ml21 {margin-left:21px !important;}
.ml22 {margin-left:22px !important;}
.ml23 {margin-left:23px !important;}
.ml24 {margin-left:24px !important;}
.ml25 {margin-left:25px !important;}
.ml26 {margin-left:26px !important;}
.ml27 {margin-left:27px !important;}
.ml28 {margin-left:28px !important;}
.ml29 {margin-left:29px !important;}
.ml30 {margin-left:30px !important;}
.ml31 {margin-left:31px !important;}
.ml32 {margin-left:32px !important;}
.ml33 {margin-left:33px !important;}
.ml34 {margin-left:34px !important;}
.ml35 {margin-left:35px !important;}
.ml36 {margin-left:36px !important;}
.ml37 {margin-left:37px !important;}
.ml38 {margin-left:38px !important;}
.ml39 {margin-left:39px !important;}
.ml40 {margin-left:40px !important;}
.ml41 {margin-left:41px !important;}
.ml42 {margin-left:42px !important;}
.ml43 {margin-left:43px !important;}
.ml44 {margin-left:44px !important;}
.ml45 {margin-left:45px !important;}
.ml46 {margin-left:46px !important;}
.ml47 {margin-left:47px !important;}
.ml48 {margin-left:48px !important;}
.ml49 {margin-left:49px !important;}
.ml50 {margin-left:50px !important;}
.ml55 {margin-left:55px !important;}
.ml60 {margin-left:60px !important;}
.ml65 {margin-left:65px !important;}
.ml70 {margin-left:70px !important;}
.ml75 {margin-left:75px !important;}
.ml80 {margin-left:80px !important;}
.ml85 {margin-left:85px !important;}
.ml90 {margin-left:90px !important;}
.ml95 {margin-left:95px !important;}
.ml100 {margin-left:100px !important;}

.mtb0 {margin-top:0 !important; margin-bottom:0 !important;}
.mtb5 {margin:5px 0 !important;}
.mtb10 {margin:10px 0 !important;}
.mtb15 {margin:15px 0 !important;}
.mtb20 {margin:20px 0 !important;}
.mtb25 {margin:25px 0 !important;}
.mtb30 {margin:30px 0 !important;}
.mtb35 {margin:35px 0 !important;}
.mtb40 {margin:40px 0 !important;}
.mtb45 {margin:45px 0 !important;}
.mtb50 {margin:50px 0 !important;}
.mtb55 {margin:55px 0 !important;}
.mtb60 {margin:60px 0 !important;}
.mtb65 {margin:65px 0 !important;}
.mtb70 {margin:70px 0 !important;}
.mtb75 {margin:75px 0 !important;}
.mtb80 {margin:80px 0 !important;}
.mtb85 {margin:85px 0 !important;}
.mtb90 {margin:90px 0 !important;}
.mtb95 {margin:95px 0 !important;}
.mtb100 {margin:100px 0 !important;}

.mrl0 {margin-left:0 !important; margin-right:0 !important;}
.mrl5 {margin:0 5px !important;}
.mrl10 {margin:0 10px !important;}
.mrl15 {margin:0 15px !important;}
.mrl20 {margin:0 20px !important;}
.mrl25 {margin:0 25px !important;}
.mrl30 {margin:0 30px !important;}
.mrl35 {margin:0 35px !important;}
.mrl40 {margin:0 40px !important;}
.mrl45 {margin:0 45px !important;}
.mrl50 {margin:0 50px !important;}
.mrl55 {margin:0 55px !important;}
.mrl60 {margin:0 60px !important;}
.mrl65 {margin:0 65px !important;}
.mrl70 {margin:0 70px !important;}
.mrl75 {margin:0 75px !important;}
.mrl80 {margin:0 80px !important;}
.mrl85 {margin:0 85px !important;}
.mrl90 {margin:0 90px !important;}
.mrl95 {margin:0 95px !important;}
.mrl100 {margin:0 100px !important;}

/* Padding */
.pd0 {padding:0px !important;}
.pd5 {padding:5px !important;}
.pd10 {padding:10px !important;}
.pd15 {padding:15px !important;}
.pd20 {padding:20px !important;}
.pd25 {padding:25px !important;}
.pd30 {padding:30px !important;}
.pd35 {padding:35px !important;}
.pd40 {padding:40px !important;}
.pd45 {padding:45px !important;}
.pd50 {padding:50px !important;}
.pd55 {padding:55px !important;}
.pd60 {padding:60px !important;}
.pd65 {padding:65px !important;}
.pd70 {padding:70px !important;}
.pd75 {padding:75px !important;}
.pd80 {padding:80px !important;}
.pd85 {padding:85px !important;}
.pd90 {padding:90px !important;}
.pd95 {padding:95px !important;}

.pt0 {padding-top:0 !important;}
.pt1 {padding-top:1px !important;}
.pt2 {padding-top:2px !important;}
.pt3 {padding-top:3px !important;}
.pt4 {padding-top:4px !important;}
.pt5 {padding-top:5px !important;}
.pt6 {padding-top:6px !important;}
.pt7 {padding-top:7px !important;}
.pt8 {padding-top:8px !important;}
.pt9 {padding-top:9px !important;}
.pt10 {padding-top:10px !important;}
.pt11 {padding-top:11px !important;}
.pt12 {padding-top:12px !important;}
.pt13 {padding-top:13px !important;}
.pt14 {padding-top:14px !important;}
.pt15 {padding-top:15px !important;}
.pt16 {padding-top:16px !important;}
.pt17 {padding-top:17px !important;}
.pt18 {padding-top:18px !important;}
.pt19 {padding-top:19px !important;}
.pt20 {padding-top:20px !important;}
.pt21 {padding-top:21px !important;}
.pt22 {padding-top:22px !important;}
.pt23 {padding-top:23px !important;}
.pt24 {padding-top:24px !important;}
.pt25 {padding-top:25px !important;}
.pt26 {padding-top:26px !important;}
.pt27 {padding-top:27px !important;}
.pt28 {padding-top:28px !important;}
.pt29 {padding-top:29px !important;}
.pt30 {padding-top:30px !important;}
.pt31 {padding-top:31px !important;}
.pt32 {padding-top:32px !important;}
.pt33 {padding-top:33px !important;}
.pt34 {padding-top:34px !important;}
.pt35 {padding-top:35px !important;}
.pt36 {padding-top:36px !important;}
.pt37 {padding-top:37px !important;}
.pt38 {padding-top:38px !important;}
.pt39 {padding-top:39px !important;}
.pt40 {padding-top:40px !important;}
.pt41 {padding-top:41px !important;}
.pt42 {padding-top:42px !important;}
.pt43 {padding-top:43px !important;}
.pt44 {padding-top:44px !important;}
.pt45 {padding-top:45px !important;}
.pt46 {padding-top:46px !important;}
.pt47 {padding-top:47px !important;}
.pt48 {padding-top:48px !important;}
.pt49 {padding-top:49px !important;}
.pt50 {padding-top:50px !important;}
.pt55 {padding-top:55px !important;}
.pt60 {padding-top:60px !important;}
.pt65 {padding-top:65px !important;}
.pt70 {padding-top:70px !important;}
.pt75 {padding-top:75px !important;}
.pt80 {padding-top:80px !important;}
.pt85 {padding-top:85px !important;}
.pt90 {padding-top:90px !important;}
.pt95 {padding-top:95px !important;}
.pt100 {padding-top:100px !important;}

.pb0 {padding-bottom:0 !important;}
.pb1 {padding-bottom:1px !important;}
.pb2 {padding-bottom:2px !important;}
.pb3 {padding-bottom:3px !important;}
.pb4 {padding-bottom:4px !important;}
.pb5 {padding-bottom:5px !important;}
.pb6 {padding-bottom:6px !important;}
.pb7 {padding-bottom:7px !important;}
.pb8 {padding-bottom:8px !important;}
.pb9 {padding-bottom:9px !important;}
.pb10 {padding-bottom:10px !important;}
.pb11 {padding-bottom:11px !important;}
.pb12 {padding-bottom:12px !important;}
.pb13 {padding-bottom:13px !important;}
.pb14 {padding-bottom:14px !important;}
.pb15 {padding-bottom:15px !important;}
.pb16 {padding-bottom:16px !important;}
.pb17 {padding-bottom:17px !important;}
.pb18 {padding-bottom:18px !important;}
.pb19 {padding-bottom:19px !important;}
.pb20 {padding-bottom:20px !important;}
.pb21 {padding-bottom:21px !important;}
.pb22 {padding-bottom:22px !important;}
.pb23 {padding-bottom:23px !important;}
.pb24 {padding-bottom:24px !important;}
.pb25 {padding-bottom:25px !important;}
.pb26 {padding-bottom:26px !important;}
.pb27 {padding-bottom:27px !important;}
.pb28 {padding-bottom:28px !important;}
.pb29 {padding-bottom:29px !important;}
.pb30 {padding-bottom:30px !important;}
.pb31 {padding-bottom:31px !important;}
.pb32 {padding-bottom:32px !important;}
.pb33 {padding-bottom:33px !important;}
.pb34 {padding-bottom:34px !important;}
.pb35 {padding-bottom:35px !important;}
.pb36 {padding-bottom:36px !important;}
.pb37 {padding-bottom:37px !important;}
.pb38 {padding-bottom:38px !important;}
.pb39 {padding-bottom:39px !important;}
.pb40 {padding-bottom:40px !important;}
.pb41 {padding-bottom:41px !important;}
.pb42 {padding-bottom:42px !important;}
.pb43 {padding-bottom:43px !important;}
.pb44 {padding-bottom:44px !important;}
.pb45 {padding-bottom:45px !important;}
.pb46 {padding-bottom:46px !important;}
.pb47 {padding-bottom:47px !important;}
.pb48 {padding-bottom:48px !important;}
.pb49 {padding-bottom:49px !important;}
.pb50 {padding-bottom:50px !important;}
.pb55 {padding-bottom:55px !important;}
.pb60 {padding-bottom:60px !important;}
.pb65 {padding-bottom:65px !important;}
.pb70 {padding-bottom:70px !important;}
.pb75 {padding-bottom:75px !important;}
.pb80 {padding-bottom:80px !important;}
.pb85 {padding-bottom:85px !important;}
.pb90 {padding-bottom:90px !important;}
.pb95 {padding-bottom:95px !important;}
.pb100 {padding-bottom:100px !important;}

.pr0 {padding-right:0 !important;}
.pr1 {padding-right:1px !important;}
.pr2 {padding-right:2px !important;}
.pr3 {padding-right:3px !important;}
.pr4 {padding-right:4px !important;}
.pr5 {padding-right:5px !important;}
.pr6 {padding-right:6px !important;}
.pr7 {padding-right:7px !important;}
.pr8 {padding-right:8px !important;}
.pr9 {padding-right:9px !important;}
.pr10 {padding-right:10px !important;}
.pr11 {padding-right:11px !important;}
.pr12 {padding-right:12px !important;}
.pr13 {padding-right:13px !important;}
.pr14 {padding-right:14px !important;}
.pr15 {padding-right:15px !important;}
.pr16 {padding-right:16px !important;}
.pr17 {padding-right:17px !important;}
.pr18 {padding-right:18px !important;}
.pr19 {padding-right:19px !important;}
.pr20 {padding-right:20px !important;}
.pr21 {padding-right:21px !important;}
.pr22 {padding-right:22px !important;}
.pr23 {padding-right:23px !important;}
.pr24 {padding-right:24px !important;}
.pr25 {padding-right:25px !important;}
.pr26 {padding-right:26px !important;}
.pr27 {padding-right:27px !important;}
.pr28 {padding-right:28px !important;}
.pr29 {padding-right:29px !important;}
.pr30 {padding-right:30px !important;}
.pr31 {padding-right:31px !important;}
.pr32 {padding-right:32px !important;}
.pr33 {padding-right:33px !important;}
.pr34 {padding-right:34px !important;}
.pr35 {padding-right:35px !important;}
.pr36 {padding-right:36px !important;}
.pr37 {padding-right:37px !important;}
.pr38 {padding-right:38px !important;}
.pr39 {padding-right:39px !important;}
.pr40 {padding-right:40px !important;}
.pr41 {padding-right:41px !important;}
.pr42 {padding-right:42px !important;}
.pr43 {padding-right:43px !important;}
.pr44 {padding-right:44px !important;}
.pr45 {padding-right:45px !important;}
.pr46 {padding-right:46px !important;}
.pr47 {padding-right:47px !important;}
.pr48 {padding-right:48px !important;}
.pr49 {padding-right:49px !important;}
.pr50 {padding-right:50px !important;}
.pr55 {padding-right:55px !important;}
.pr60 {padding-right:60px !important;}
.pr65 {padding-right:65px !important;}
.pr70 {padding-right:70px !important;}
.pr75 {padding-right:75px !important;}
.pr80 {padding-right:80px !important;}
.pr85 {padding-right:85px !important;}
.pr90 {padding-right:90px !important;}
.pr95 {padding-right:95px !important;}
.pr100 {padding-right:100px !important;}

.pl0 {padding-left:0 !important;}
.pl1 {padding-left:1px !important;}
.pl2 {padding-left:2px !important;}
.pl3 {padding-left:3px !important;}
.pl4 {padding-left:4px !important;}
.pl5 {padding-left:5px !important;}
.pl6 {padding-left:6px !important;}
.pl7 {padding-left:7px !important;}
.pl8 {padding-left:8px !important;}
.pl9 {padding-left:9px !important;}
.pl10 {padding-left:10px !important;}
.pl11 {padding-left:11px !important;}
.pl12 {padding-left:12px !important;}
.pl13 {padding-left:13px !important;}
.pl14 {padding-left:14px !important;}
.pl15 {padding-left:15px !important;}
.pl16 {padding-left:16px !important;}
.pl17 {padding-left:17px !important;}
.pl18 {padding-left:18px !important;}
.pl19 {padding-left:19px !important;}
.pl20 {padding-left:20px !important;}
.pl21 {padding-left:21px !important;}
.pl22 {padding-left:22px !important;}
.pl23 {padding-left:23px !important;}
.pl24 {padding-left:24px !important;}
.pl25 {padding-left:25px !important;}
.pl26 {padding-left:26px !important;}
.pl27 {padding-left:27px !important;}
.pl28 {padding-left:28px !important;}
.pl29 {padding-left:29px !important;}
.pl30 {padding-left:30px !important;}
.pl31 {padding-left:31px !important;}
.pl32 {padding-left:32px !important;}
.pl33 {padding-left:33px !important;}
.pl34 {padding-left:34px !important;}
.pl35 {padding-left:35px !important;}
.pl36 {padding-left:36px !important;}
.pl37 {padding-left:37px !important;}
.pl38 {padding-left:38px !important;}
.pl39 {padding-left:39px !important;}
.pl40 {padding-left:40px !important;}
.pl41 {padding-left:41px !important;}
.pl42 {padding-left:42px !important;}
.pl43 {padding-left:43px !important;}
.pl44 {padding-left:44px !important;}
.pl45 {padding-left:45px !important;}
.pl46 {padding-left:46px !important;}
.pl47 {padding-left:47px !important;}
.pl48 {padding-left:48px !important;}
.pl49 {padding-left:49px !important;}
.pl50 {padding-left:50px !important;}
.pl55 {padding-left:55px !important;}
.pl60 {padding-left:60px !important;}
.pl65 {padding-left:65px !important;}
.pl70 {padding-left:70px !important;}
.pl75 {padding-left:75px !important;}
.pl80 {padding-left:80px !important;}
.pl85 {padding-left:85px !important;}
.pl90 {padding-left:90px !important;}
.pl95 {padding-left:95px !important;}
.pl100 {padding-left:100px !important;}

/* Border */
.bd0 {border:0}
.bdt0 {border-top:0}
.bdb0 {border-bottom:0;}
.bdr0 {border-right:0;}
.bdl0 {border-left:0;}

/* Font Size */
.fs12 {font-size:12px !important;}
.fs13 {font-size:13px !important;}
.fs14 {font-size:14px !important;}
.fs15 {font-size:15px !important;}
.fs16 {font-size:16px !important;}
.fs17 {font-size:17px !important;}
.fs18 {font-size:18px !important;}
.fs19 {font-size:19px !important;}
.fs20 {font-size:20px !important;}
.fs21 {font-size:21px !important;}
.fs22 {font-size:22px !important;}
.fs23 {font-size:23px !important;}
.fs24 {font-size:24px !important;}
.fs25 {font-size:25px !important;}
.fs26 {font-size:26px !important;}
.fs27 {font-size:27px !important;}
.fs28 {font-size:28px !important;}
.fs29 {font-size:29px !important;}
.fs30 {font-size:30px !important;}
.fs31 {font-size:31px !important;}
.fs32 {font-size:32px !important;}
.fs33 {font-size:33px !important;}
.fs34 {font-size:34px !important;}
.fs35 {font-size:35px !important;}
.fs36 {font-size:36px !important;}
.fs37 {font-size:37px !important;}
.fs38 {font-size:38px !important;}
.fs39 {font-size:39px !important;}
.fs40 {font-size:40px !important;}
.fs41 {font-size:41px !important;}
.fs42 {font-size:42px !important;}
.fs43 {font-size:43px !important;}
.fs44 {font-size:44px !important;}
.fs45 {font-size:45px !important;}
.fs46 {font-size:46px !important;}
.fs47 {font-size:47px !important;}
.fs48 {font-size:48px !important;}
.fs49 {font-size:49px !important;}
.fs50 {font-size:50px !important;}
.fs51 {font-size:51px !important;}
.fs52 {font-size:52px !important;}
.fs53 {font-size:53px !important;}
.fs54 {font-size:54px !important;}
.fs55 {font-size:55px !important;}
.fs56 {font-size:56px !important;}
.fs57 {font-size:57px !important;}
.fs58 {font-size:58px !important;}
.fs59 {font-size:59px !important;}
.fs60 {font-size:60px !important;}
.fs61 {font-size:61px !important;}
.fs62 {font-size:62px !important;}
.fs63 {font-size:63px !important;}
.fs64 {font-size:64px !important;}
.fs65 {font-size:65px !important;}
.fs66 {font-size:66px !important;}
.fs67 {font-size:67px !important;}
.fs68 {font-size:68px !important;}
.fs69 {font-size:69px !important;}
.fs70 {font-size:70px !important;}
.fs75 {font-size:75px !important;}
.fs80 {font-size:80px !important;}
.fs85 {font-size:85px !important;}
.fs90 {font-size:90px !important;}
.fs95 {font-size:95px !important;}
.fs100 {font-size:100px !important;}


